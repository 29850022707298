import * as React from 'react'

import {
    goCalendarioBtn
} from '../components/styles/Buttons.styles'

const CalendarioBtn = ({ children }) => (
  <button css={goCalendarioBtn}>{ children }</button>
)

export default CalendarioBtn

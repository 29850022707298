import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/covid19.png'
import Cta from '../images/new/banners/covid19-2.png'
import Rocket from '../images/new/banners/border.png'
import Ellipse from '../images/new/icons/ellipse2.svg'
import Button from '../components/CalendarioBtn'
import { Link } from 'gatsby'

import { 
  section,
  HeaderBanner,
  container,
  content,
  columns,
  column,
  desktop,
  mobile,
  box2
} from '../components/styles/Base.styles'

const CovidPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="COVID-19 | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]} style={{ paddingBottom: 0 }}>
      <div css={container}>
        <h1>COVID-19</h1>
        <p>Para frequentar as escolas Kingdom School, de maneira presencial, apresentamos o “Guia Prático” que deverá ser observado para garantir uma convivência segura e saudável para todos os segmentos das escolas. Esse guia, está em consonância com as medidas estabelecidas pelos Órgãos Competentes, cujas referências encontram-se no final do documento.</p>
        <p>Além disso, ele está embasado em nosso método que procura o desenvolvimento natural, de maneira simples, fundamentado no amor.</p>
        <p>Sendo assim, contamos com a compreensão de todos e esperamos estar em uma mesma conexão para, juntos, vencermos tudo isso. É expressamente importante o conhecimento e atendimento do conteúdo deste guia, para que no momento que os pontos forem relembrados, ninguém se incomode com isso, pois estamos atentos às normas de saúde.</p>
        <h2>Procedimentos de Prevenção à COVID-19</h2>
        <p>Das medidas de distanciamento físico:</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Os espaços comuns, são utilizados de forma escalonada, de acordo com a grade horária de cada turma.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Os atendimentos aos pais acontecem de forma presencial, ou on-line, a depender da necessidade de cada família. Esses agendamentos devem ser feitos com a Secretaria, ou direto com a Coordenação/Orientação Educacional.</p>
            </li>
          </ul>
        </div>
        <p>Das medidas de higiene:</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Todos os funcionários usam o Equipamento de Proteção Individual (EPI), conforme estabelecido pelos Órgãos Competentes. Pedimos que preparem as crianças e expliquem a razão da utilização, sempre que perguntarem.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Suportes de álcool em gel 70% foram espalhados pela escola para a higienização das mãos de todos, porém as crianças irão usar mediante fiscalização dos professores.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>As crianças a partir de 3 anos devem usar máscaras. É necessário enviar sempre mais máscaras na mochila, para que sejam trocadas a cada duas horas. Caso a criança tenha alguma restrição ao uso de máscara, os responsáveis devem informar a escola.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>As lixeiras são de pedal, ou abertas, para evitar o contato das mãos com a tampa da lixeira.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Evitar enviar as crianças com adereços, tais como anéis e outros. E mantê-las sempre com as unhas cortadas.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Os lençóis, fronhas, toalhas, escova de dentes e demais objetos de higiene pessoal deverão ser trocados pela família, de acordo com a orientação da professora (no caso dos itens de sono e banho, é só para alunos que fazem o programa Contraturno).</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>A limpeza dos locais de maior circulação é feita com frequência. Todas as salas possuem um kit de limpeza, que fica em local seguro, para que os professores possam manter o espaço sempre higienizado. Os brinquedos também são higienizados frequentemente.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Para maior ventilação e evitar o toque nas maçanetas, as atividades são, preferencialmente, feitas com com as portas e janelas abertas e dando prioridade aos espaços abertos, onde a ventilação seja melhor.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Todos os dias a criança deverá trazer a sua garrafa de água, devidamente higienizada.</p>
            </li>
          </ul>
        </div>
        <p>Do Drive-Thru:</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Esse sistema visa facilitar o embarque e desembarque das crianças, nos momentos de entrada e saída, além de evitar aglomerações e maior circulação de pessoas dentro da Escola.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Ao chegarem na Escola, alguém da recepção se aproximará do carro para recepcionar e direcionar a criança até a sala. A mesma forma acontecerá no momento da saída, a família pode aguardar dentro do carro que a equipe de portaria solicitará que as recepcionistas busquem a criança.</p>
            </li>
          </ul>
        </div>
        <img src={Cta} alt='' style={{ marginTop: 0, width: '100%' }} />
        <p>Do calendário escolar:</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>O Calendário Escolar deverá seguir suas datas, de acordo com o documento já disponibilizado às famílias.</p>
            </li>
          </ul>
        </div>
        <p>Base do Protocolo de Medidas de Prevenção à COVID-19</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Protocolos e Medidas de Segurança constantes no Decreto Distrital no 40.939/2020.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Nota Técnica No 34/2020, da Secretaria de Estado de Saúde do Distrito Federal.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Protocolo de Retorno das Atividades Presenciais: Rede Privada de Ensino, da Secretaria de Estado de Educação do Distrito Federal.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Portaria Conjunta no 20, do Ministério da Economia e Secretaria Especial de Previdência e Trabalho.</p>
            </li>
          </ul>
        </div>
        <p>Condutas em caso de Testagem Positiva (Medidas Pedagógicas e Administrativas)</p>
        <div css={box2} style={{ marginBottom: 48 }}>
          <ul>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Caso alguma criança teste positivo à Covid-19, apresente sintomas, ou tenha tido contato direto com algum familiar que esteja com a doença, ela deverá ser afastada pelo tempo que o médico determinar.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Em casos assim, a escola deverá ser avisada imediatamente.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>É de responsabilidade das famílias manter a escola informada sobre familiares que tenham testado positivo e sejam diretamente ligados ao aluno.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>A escola fará a sanitização da sala de aula e acompanhará possíveis manifestações de sintomas nas demais crianças e colaboradores que tiveram contato com essa criança.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Após o período de quarentena, o aluno poderá voltar normalmente às atividades presenciais. Para isso, é necessário a apresentação de um atestado médico comprovando que o aluno está apto.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>Em casos de alunos, ou colaboradores que testam positivo, a escola informará às demais famílias, caso seja necessário, de acordo as singularidades de cada caso.</p>
            </li>
            <li>
              <img src={Ellipse} alt='' style={{ width: 'auto' }} />
              <p>As demandas serão analisadas individualmente e, caso aquela turma tenha mais crianças, ou colaboradores que apresentem sintomas, a situação será avaliada junto com a equipe de enfermagem e, assim, providências serão tomadas (suspensão da turma, sanitização, etc).</p>
            </li>
          </ul>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 48 }}><Link to='/calendario'><Button>ACOMPANHE O NOSSO ANO LETIVO!</Button></Link></div>
        <div css={mobile}>
          <img src={Rocket} alt='' style={{ width: 'auto', marginBottom: 0, display: 'flex', justifyContent: 'center' }} />
        </div>
        <div css={columns}>
          <div css={column} style={{ display: 'flex', alignItems: 'end' }}><img css={desktop} src={Rocket} alt=''/></div>
          <div css={column}>
            
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default CovidPage
